/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import TextFieldType from './TextFieldType';
import TextareaFieldType from './TextareaFieldType';
import DateFieldType from './DateFieldType';
import RadioFieldType from './RadioFieldType';
import CheckboxFieldType from './CheckboxFieldType';
import SelectFieldType from './SelectFieldType';
import EmailFieldType from './EmailFieldType';
import ImageFieldType from './ImageFieldType';
import FileFieldType from './FileFieldType';

const propTypes = {
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  display: PropTypes.oneOf(['field', 'fieldPreview', 'fieldValue', 'datatable']),
  fieldDefinition: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  fieldValue: PropTypes.shape({}),
  onUpdateCallback: PropTypes.func,
  isDebounced: PropTypes.bool,
};
const defaultProps = {
  disabled: false,
  visible: true,
  display: 'field',
  fieldValue: null,
  onUpdateCallback: () => null,
  isDebounced: false,
};

function CustomField(props) {
  switch (props.fieldDefinition.type) {
    case 'text':
      return <TextFieldType {...props} />;
    case 'textarea':
      return <TextareaFieldType {...props} />;
    case 'date':
      return <DateFieldType {...props} />;
    case 'radio':
      return <RadioFieldType {...props} />;
    case 'checkbox':
      return <CheckboxFieldType {...props} />;
    case 'select':
      return <SelectFieldType {...props} />;
    case 'email':
      return <EmailFieldType {...props} />;
    case 'image':
      return <ImageFieldType {...props} />;
    case 'file':
      return <FileFieldType {...props} />;
    default:
      return <span className="text-muted">Field not available</span>;
  }
}

CustomField.propTypes = propTypes;
CustomField.defaultProps = defaultProps;

export default CustomField;
