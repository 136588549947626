import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormGroup, ControlLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import convertSignedUrlToCloudfrontUrl from '../../utils/convertSignedUrlToCloudfrontUrl';
import StoryChief from '@/storychief';
import ConditionalWrapper from '@/storychief/components/ConditionalWrapper';
import { useDebounce, useDidUpdateEffect, usePrevious } from '@/storychief/hooks';
import s3UploadPost from '@/s3-upload-post';
import FileDragDrop from '@/storychief/components/custom-fields/FileDragDrop';

const propTypes = {
  display: PropTypes.oneOf(['field', 'fieldPreview', 'fieldValue', 'datatable']),
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  allowedFileTypes: PropTypes.arrayOf(
    PropTypes.shape({
      mime: PropTypes.string.isRequired,
      extension: PropTypes.string.isRequired,
    }),
  ),
  fieldDefinition: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    important: PropTypes.bool,
    description: PropTypes.string,
    default: PropTypes.string,
    uploadFolder: PropTypes.string,
  }).isRequired,
  fieldValue: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  onUpdateCallback: PropTypes.func.isRequired,
  maxFileSize: PropTypes.number,
  isDebounced: PropTypes.bool,
};
const defaultProps = {
  disabled: false,
  visible: true,
  display: 'field',
  allowedFileTypes: [
    { mime: 'application/msword', extension: '.doc' },
    {
      mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      extension: '.docx',
    },
    { mime: 'text/plain', extension: '.txt' },
    { mime: 'application/vnd.ms-excel', extension: '.xls' },
    {
      mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      extension: '.xlsx',
    },
    { mime: 'application/pdf', extension: '.pdf' },
    { mime: 'application/rtf', extension: '.rtf' },
  ],
  maxFileSize: 2000097,
  isDebounced: false,
};

function FileFieldType(props) {
  const { allowedFileTypes, fieldValue, visible, display, disabled, fieldDefinition, isDebounced } =
    props;
  const [file, setFile] = useState(fieldValue.value || '');
  const [status, setStatus] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const prevDisabled = usePrevious(isDebounced);
  const prevDisplay = usePrevious(display);
  const prevVisible = usePrevious(visible);

  const fileInput = useRef();

  const change = useDebounce(file, props.isDebounced ? 200 : 0);

  useDidUpdateEffect(() => {
    saveCustomFieldValues(change);
  }, [change]);

  useEffect(() => {
    // check if prop fieldValue has changed to update state
    if (disabled || disabled !== prevDisabled || prevDisplay !== 'field') {
      setFile(fieldValue.value);
    }

    if (!visible && prevVisible) {
      handleChange('');
    }
  }, [disabled, visible]);

  function onFileClick() {
    if (!props.disabled) {
      const current = fileInput.current;

      current.value = null;
      current.click();
    }
  }

  function handleOnFileUpload(_files) {
    const _file = _files[0];

    if (!allowedFileTypes.find((fileType) => _file.type.indexOf(fileType.mime) !== -1)) {
      const message = `Only ${allowedFileTypes
        .map((fileType) => fileType.extension)
        .join(', ')} files are allowed`;
      // eslint-disable-next-line no-alert
      window.alert(message);
      return;
    }

    if (_file.size > props.maxFileSize) {
      const message = `The file you are uploading is bigger than the max allowed of  ${
        props.maxFileSize / 1000000
      } MB`;
      // eslint-disable-next-line no-alert
      window.alert(message);
      return;
    }

    setUploadProgress(0);
    setIsDeleting(false);
    setStatus('processing');

    s3UploadPost(_file, {
      onProgress: (_, progress) => setUploadProgress(progress),
      onFinish: (_, publicUrl) => {
        setUploadProgress(0);
        setIsDeleting(true);
        setStatus('');

        handleChange(publicUrl);
      },
    });
  }

  function onFileRemove() {
    setUploadProgress(0);
    setIsDeleting(true);
    setStatus('');

    handleChange('');
  }

  function handleChange(url) {
    setFile(url ? convertSignedUrlToCloudfrontUrl(url, StoryChief.cloudfront.url) : url);
  }

  function saveCustomFieldValues(changedFile) {
    props.onUpdateCallback({
      key: fieldValue.key,
      value: changedFile,
    });
  }

  function getFileName() {
    return file.substring(file.lastIndexOf('/') + 1, file.lastIndexOf('_'));
  }

  function getFileType() {
    const regex = /(?:\.([^.]+))?$/;
    return regex.exec(file)[1];
  }

  const classNameWrapper = classNames(
    `container-upload-file row-table ${file ? '' : 'empty'} ${status}`,
    { empty: !file },
  );

  if (!visible) {
    return <div />;
  }

  switch (display) {
    case 'datatable':
      return (
        <a href={file} target="_blank" rel="noreferrer noopener" download>
          {getFileName()}.{getFileType()}
        </a>
      );
    case 'fieldValue':
      return (
        <div className="space-2">
          <ConditionalWrapper
            condition={!!file}
            wrapper={(children) => (
              <a href={file} target="_blank" rel="noreferrer noopener">
                {children}
              </a>
            )}
          >
            {file ? `${getFileName()}.${getFileType()}` : '-'}
          </ConditionalWrapper>
        </div>
      );
    case 'fieldPreview':
      return <span className="text-muted">No preview available</span>;
    default:
      return (
        <FormGroup controlId={fieldDefinition.name} className="custom-field">
          {!!fieldDefinition.label && (
            <ControlLabel bsClass="control-label">
              {fieldDefinition.label}
              {fieldDefinition.important && !fieldDefinition.description && (
                <em className="icon-attention-alt text-warning" />
              )}
              {fieldDefinition.important && fieldDefinition.description && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id={`help-${fieldDefinition.name}`}>
                      Important! {fieldDefinition.description}
                    </Tooltip>
                  }
                >
                  <span className="icon-attention-alt text-warning" />
                </OverlayTrigger>
              )}
              {!fieldDefinition.important && fieldDefinition.description && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id={`help-${fieldDefinition.name}`}>
                      {fieldDefinition.description}
                    </Tooltip>
                  }
                >
                  <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
                </OverlayTrigger>
              )}
            </ControlLabel>
          )}
          <FileDragDrop
            allowedFileTypes={allowedFileTypes}
            onDrop={handleOnFileUpload}
            className={classNameWrapper}
          >
            <div className="container-upload-file__main">
              {!file ? (
                <div>
                  <div className="children space-top-5 space-5 gutter-3">
                    <button
                      type="button"
                      onClick={onFileClick}
                      disabled={disabled}
                      className="btn btn-default btn-outline"
                    >
                      <em className="icon-upload" /> Upload file
                    </button>
                    <div className="text-muted space-top-2 xsmall initial-line-heightl">
                      {allowedFileTypes.map((fileType) => fileType.extension).join(', ')}
                    </div>
                    {status === 'processing' && isDeleting === false && (
                      <div className="progress progress-small">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          aria-valuenow={uploadProgress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${uploadProgress}%` }}
                        >
                          <span className="sr-only">{uploadProgress} Complete</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <input
                    type="file"
                    ref={fileInput}
                    disabled={disabled}
                    onChange={(e) => handleOnFileUpload(e.target.files)}
                    style={{ display: 'none' }}
                  />
                </div>
              ) : (
                <div className="gutter-2 space-top-2 space-2 text-left">
                  <span>
                    <a href={file} target="_blank" rel="noreferrer noopener">
                      {getFileName()}.{getFileType()}
                    </a>
                  </span>
                  {!disabled && (
                    <button
                      type="button"
                      onClick={onFileRemove}
                      disabled={disabled}
                      className="btn-chromeless btn-delete icon-cancel"
                    >
                      <span className="sr-only">Remove file</span>
                    </button>
                  )}
                </div>
              )}
            </div>
          </FileDragDrop>
        </FormGroup>
      );
  }
}

FileFieldType.propTypes = propTypes;
FileFieldType.defaultProps = defaultProps;

export default FileFieldType;
