import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { convertFromRaw } from 'draft-js';
import { FormControl, FormGroup, ControlLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import RichEditor from '../editors/RichEditor';
import defaultConverter from '@/storyDestinations/converter/defaultConverter';
import TruncateWithTooltip from '@/storychief/components/TruncateWithTooltip';
import linkify from '@/storychief/utils/linkify';
import getPlainTextFromHTML from '@/campaigns/utils/getPlainTextFromHTML';
import { useDebounce, useDidUpdateEffect, usePrevious } from '@/storychief/hooks';

const propTypes = {
  display: PropTypes.oneOf(['field', 'fieldPreview', 'fieldValue', 'datatable']),
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  fieldDefinition: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    important: PropTypes.bool,
    rich: PropTypes.bool,
    isTextOnly: PropTypes.bool,
    description: PropTypes.string,
    default: PropTypes.string,
    disabled_inline_styles: PropTypes.arrayOf(PropTypes.string),
    disabled_block_styles: PropTypes.arrayOf(PropTypes.string),
    disabled_plugins: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  fieldValue: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  onUpdateCallback: PropTypes.func.isRequired,
  isDebounced: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  visible: true,
  display: 'field',
  isDebounced: false,
};

function TextareaFieldType(props) {
  const { fieldDefinition, disabled, visible, display, fieldValue, isDebounced } = props;

  // States
  const [text, setText] = useState(fieldValue.value || '');

  // Previous
  const prevDisabled = usePrevious(isDebounced);
  const prevDisplay = usePrevious(visible);
  const prevVisible = usePrevious(display);

  // Effects
  const change = useDebounce(text, isDebounced ? 400 : 0, null);

  useDidUpdateEffect(() => {
    handleSaveCustomFieldValues(change);
  }, [change]);

  useEffect(() => {
    // Overwrite the internal state with new prop values if the component is in view-only mode or if
    // the disabled value changes.
    if (disabled || disabled !== prevDisabled || prevDisplay !== 'field') {
      setText(fieldValue.value || '');
    }

    if (!visible && prevVisible) {
      setText('');
    }
  }, [disabled, display]);

  function handleChange(event) {
    if (fieldDefinition.rich) {
      // Skip if value is not a DraftJS object.
      if (event.target.value) {
        setText(defaultConverter(convertFromRaw(event.target.value)));
      }
    } else {
      setText(event.target.value);
    }
  }

  function handleSaveCustomFieldValues(changedValue) {
    props.onUpdateCallback({
      key: fieldValue.key,
      value: changedValue,
    });
  }

  if (!visible) {
    return null;
  }

  switch (display) {
    case 'datatable':
      return <TruncateWithTooltip text={fieldValue.value} delayShow={1000} />;
    case 'fieldValue':
      if (fieldDefinition.rich) {
        if (fieldDefinition.isTextOnly) {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: fieldValue.value ? linkify(getPlainTextFromHTML(fieldValue.value)) : '-',
              }}
            />
          );
        }
        return <div dangerouslySetInnerHTML={{ __html: fieldValue.value || '-' }} />;
      }
      return (
        <p
          dangerouslySetInnerHTML={{ __html: fieldValue.value ? linkify(fieldValue.value) : '-' }}
        />
      );
    default:
      return (
        <FormGroup controlId={fieldDefinition.name} className="custom-field">
          <ControlLabel bsClass="control-label">
            {fieldDefinition.label}
            {fieldDefinition.important && !fieldDefinition.description && (
              <em className="icon-attention-alt text-warning" />
            )}
            {fieldDefinition.important && fieldDefinition.description && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`help-${fieldDefinition.name}`}>
                    Important! {fieldDefinition.description}
                  </Tooltip>
                }
              >
                <span className="icon-attention-alt text-warning" />
              </OverlayTrigger>
            )}
            {!fieldDefinition.important && fieldDefinition.description && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`help-${fieldDefinition.name}`}>
                    {fieldDefinition.description}
                  </Tooltip>
                }
              >
                <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
              </OverlayTrigger>
            )}
          </ControlLabel>
          {fieldDefinition.rich ? (
            <RichEditor
              disabled={disabled}
              content={text}
              updateContent={(content) => handleChange({ target: { value: content } })}
              formControl
              emojiPicker
              formControlAutoHeight
              formControlId={fieldDefinition.name}
              disabled_block_styles={fieldDefinition.disabled_block_styles || []}
              disabled_inline_styles={fieldDefinition.disabled_inline_styles || []}
              disabled_plugins={fieldDefinition.disabled_plugins || []}
            />
          ) : (
            <FormControl
              disabled={disabled}
              name={fieldDefinition.name}
              componentClass="textarea"
              value={text || ''}
              onChange={handleChange}
            />
          )}
        </FormGroup>
      );
  }
}

TextareaFieldType.propTypes = propTypes;
TextareaFieldType.defaultProps = defaultProps;

export default TextareaFieldType;
