import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import { useDebounce, useDidUpdateEffect, usePrevious } from '@/storychief/hooks';

const propTypes = {
  display: PropTypes.oneOf(['field', 'fieldPreview', 'fieldValue', 'datatable']),
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  fieldDefinition: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    important: PropTypes.bool,
    description: PropTypes.string,
    default: PropTypes.string,
  }).isRequired,
  fieldValue: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  onUpdateCallback: PropTypes.func.isRequired,
  isDebounced: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  visible: true,
  display: 'field',
  isDebounced: false,
};

function EmailFieldType(props) {
  const { fieldDefinition, disabled, visible, display, fieldValue, isDebounced } = props;

  const [email, setEmail] = useState(fieldValue.value || '');

  // Previous
  const prevDisabled = usePrevious(isDebounced);
  const prevDisplay = usePrevious(display);
  const prevVisible = usePrevious(visible);

  // Effects
  const change = useDebounce(email, isDebounced ? 400 : 0, null);

  useDidUpdateEffect(() => {
    handleSaveCustomFieldValues(change);
  }, [change]);

  useEffect(() => {
    // Overwrite the internal state with new prop values if the component is in view-only mode or if
    // the disabled value changes.
    if (disabled || disabled !== prevDisabled || prevDisplay !== 'field') {
      setEmail(fieldValue.value || '');
    }

    if (!visible && prevVisible) {
      setEmail('');
    }
  }, [disabled, display]);

  function handleOnChange(event) {
    setEmail(event.target.value);
  }

  function handleSaveCustomFieldValues(changedValue) {
    props.onUpdateCallback({
      ...fieldValue,
      value: changedValue,
    });
  }

  if (!visible) {
    return null;
  }

  switch (display) {
    case 'datatable':
      return <span>{fieldValue.value}</span>;
    case 'fieldValue':
      return <div className="space-2">{fieldValue.value || '-'}</div>;
    default:
      return (
        <FormGroup controlId={fieldDefinition.id} className="custom-field">
          <ControlLabel bsClass="control-label">
            {fieldDefinition.label}
            {fieldDefinition.important && !fieldDefinition.description && (
              <em className="icon-attention-alt text-warning" />
            )}
            {fieldDefinition.important && fieldDefinition.description && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`help-${fieldDefinition.id}`}>
                    Important! {fieldDefinition.description}
                  </Tooltip>
                }
              >
                <span className="icon-attention-alt text-warning" />
              </OverlayTrigger>
            )}
            {!fieldDefinition.important && fieldDefinition.description && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`help-${fieldDefinition.name}`}>
                    {fieldDefinition.description}
                  </Tooltip>
                }
              >
                <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
              </OverlayTrigger>
            )}
          </ControlLabel>
          <FormControl
            disabled={disabled}
            type={fieldDefinition.type}
            name={fieldDefinition.name}
            componentClass="input"
            value={email}
            onChange={handleOnChange}
          />
        </FormGroup>
      );
  }
}

EmailFieldType.propTypes = propTypes;
EmailFieldType.defaultProps = defaultProps;

export default EmailFieldType;
