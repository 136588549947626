import PropTypes from 'prop-types';
import {
  InputGroup,
  FormControl,
  FormGroup,
  ControlLabel,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify-icon/react';
import DateRangePicker from '@/storychief/components/DateRangePicker';
import { useDebounce, useDidUpdateEffect, usePrevious } from '@/storychief/hooks';
import { formatDate, getISODate } from '@/date';
import getDateObjectWithTimezone from '@/date/getDateObjectWithTimezone';

const propTypes = {
  display: PropTypes.oneOf(['field', 'fieldPreview', 'fieldValue', 'datatable']),
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  fieldDefinition: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    important: PropTypes.bool,
    description: PropTypes.string,
    default: PropTypes.string,
  }).isRequired,
  fieldValue: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  onUpdateCallback: PropTypes.func.isRequired,
  isDebounced: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  visible: true,
  display: 'field',
  isDebounced: false,
};

function DateFieldType(props) {
  const { fieldDefinition, disabled, visible, display, fieldValue, isDebounced } = props;

  // States
  const [date, setDate] = useState(fieldValue.value || '');

  // Previous
  const prevDisabled = usePrevious(isDebounced);
  const prevDisplay = usePrevious(visible);
  const prevVisible = usePrevious(display);

  // Effects
  const change = useDebounce(date, isDebounced ? 400 : 0, null);

  useDidUpdateEffect(() => {
    handleSaveCustomFieldValues(change);
  }, [change]);

  useEffect(() => {
    // Overwrite the internal state with new prop values if the component is in view-only mode or if
    // the disabled value changes.
    if (disabled || disabled !== prevDisabled || prevDisplay !== 'field') {
      setDate(fieldValue.value || '');
    }

    if (!visible && prevVisible) {
      handleClear();
    }
  }, [disabled, visible]);

  function handleOnChange(changedStartDate) {
    setDate(getISODate(changedStartDate));
  }

  function handleClear() {
    setDate('');
  }

  function handleSaveCustomFieldValues(changedValue) {
    props.onUpdateCallback({ ...fieldValue, value: changedValue });
  }

  const _date = date.length ? getDateObjectWithTimezone(date) : null;

  if (!visible) {
    return null;
  }

  switch (display) {
    case 'datatable':
      return <span>{date ? formatDate(date) : ''}</span>;
    case 'fieldValue':
      return <div className="space-2">{date ? formatDate(date) : '-'}</div>;
    default:
      return (
        <FormGroup controlId={fieldDefinition.name} className="custom-field">
          <ControlLabel bsClass="control-label">
            {fieldDefinition.label}
            {fieldDefinition.important && !fieldDefinition.description && (
              <em className="icon-attention-alt text-warning" />
            )}
            {fieldDefinition.important && fieldDefinition.description && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`help-${fieldDefinition.name}`}>
                    Important! {fieldDefinition.description}
                  </Tooltip>
                }
              >
                <span className="icon-attention-alt text-warning" />
              </OverlayTrigger>
            )}
            {!fieldDefinition.important && fieldDefinition.description && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`help-${fieldDefinition.name}`}>
                    {fieldDefinition.description}
                  </Tooltip>
                }
              >
                <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
              </OverlayTrigger>
            )}
          </ControlLabel>
          {disabled ? (
            <InputGroup>
              <FormControl
                disabled={disabled}
                type="text"
                componentClass="input"
                readOnly
                value={date ? formatDate(date) : ''}
              />
              <span className="input-group-addon">
                <Icon icon="clarity:calendar-line" inline width="16" />
              </span>
            </InputGroup>
          ) : (
            <DateRangePicker
              startDate={_date}
              name={fieldDefinition.name}
              onChange={handleOnChange}
              onClear={handleClear}
              isDisabled={disabled}
              triggerType="input"
            />
          )}
        </FormGroup>
      );
  }
}

DateFieldType.propTypes = propTypes;
DateFieldType.defaultProps = defaultProps;

export default DateFieldType;
