import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { escape } from 'lodash-es';
import { ControlLabel, FormControl, FormGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import TruncateWithTooltip from '@/storychief/components/TruncateWithTooltip';
import linkify from '@/storychief/utils/linkify';
import { useDebounce, useDidUpdateEffect, usePrevious } from '@/storychief/hooks';

const propTypes = {
  display: PropTypes.oneOf(['field', 'fieldPreview', 'fieldValue', 'datatable']),
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  fieldDefinition: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    important: PropTypes.bool,
    description: PropTypes.string,
    default: PropTypes.string,
  }).isRequired,
  fieldValue: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  onUpdateCallback: PropTypes.func.isRequired,
  isDebounced: PropTypes.bool,
};
const defaultProps = {
  disabled: false,
  visible: true,
  display: 'field',
  isDebounced: false,
};

function TextFieldType(props) {
  const { fieldDefinition, disabled, visible, display, fieldValue, isDebounced } = props;

  const [text, setText] = useState(fieldValue.value || '');

  // Previous
  const prevDisabled = usePrevious(isDebounced);
  const prevDisplay = usePrevious(visible);
  const prevVisible = usePrevious(display);

  // Effects
  const change = useDebounce(text, isDebounced ? 400 : 0, null);

  useDidUpdateEffect(() => {
    handleSaveCustomFieldValues(change);
  }, [change]);

  useEffect(() => {
    // Overwrite the internal state with new prop values if the component is in view-only mode or if
    // the disabled value changes.
    if (disabled || disabled !== prevDisabled || prevDisplay !== 'field') {
      setText(fieldValue.value || '');
    }

    if (!visible && prevVisible) {
      setText('');
    }
  }, [disabled, visible]);

  function handleOnChange(event) {
    setText(event.target.value);
  }

  function handleSaveCustomFieldValues(changedValue) {
    props.onUpdateCallback({
      ...fieldValue,
      value: changedValue,
    });
  }

  if (!visible) {
    return null;
  }

  switch (display) {
    case 'datatable':
      return <TruncateWithTooltip text={fieldValue.value} delayShow={1000} />;
    case 'fieldValue':
      return (
        <div
          className="space-2 text-word-break"
          dangerouslySetInnerHTML={{
            __html: fieldValue.value ? linkify(escape(fieldValue.value)) : '-',
          }}
        />
      );
    default:
      return (
        <FormGroup controlId={fieldDefinition.name} className="custom-field">
          <ControlLabel bsClass="control-label">
            {fieldDefinition.label}
            {fieldDefinition.important && !fieldDefinition.description && (
              <em className="icon-attention-alt text-warning" />
            )}
            {fieldDefinition.important && fieldDefinition.description && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`help-${fieldDefinition.name}`}>
                    Important! {fieldDefinition.description}
                  </Tooltip>
                }
              >
                <span className="icon-attention-alt text-warning" />
              </OverlayTrigger>
            )}
            {!fieldDefinition.important && fieldDefinition.description && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`help-${fieldDefinition.name}`}>
                    {fieldDefinition.description}
                  </Tooltip>
                }
              >
                <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
              </OverlayTrigger>
            )}
          </ControlLabel>
          <FormControl
            disabled={disabled}
            type={fieldDefinition.type}
            name={fieldDefinition.name}
            componentClass="input"
            value={text}
            onChange={handleOnChange}
          />
        </FormGroup>
      );
  }
}

TextFieldType.propTypes = propTypes;
TextFieldType.defaultProps = defaultProps;

export default TextFieldType;
