import { ContentState, convertFromHTML } from 'draft-js';

/**
 * Convert HTML markup to plain text using Draft.js
 * @param {string} markup
 * @returns {string}
 */
export default function getPlainTextFromHTML(markup) {
  const blocksFromHTML = convertFromHTML(markup);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );

  return state.getPlainText(' ');
}
