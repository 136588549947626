import urlRegex from 'url-regex';
import appendUrlProtocol from '@/storychief/utils/appendUrlProtocol';

function getWrappedUrl(url) {
  const isEmail = url.includes('@');

  if (isEmail) {
    return `<a href="mailto:${url}">${url}</a>`;
  }

  return `<a href="${appendUrlProtocol(url)}" target="_blank" rel="noopener noreferrer">${url}</a>`;
}

/**
 * Wrap URLs and emails inside a string with anchor tag.
 * @param {string} text
 * @returns {string|null}
 */
export default function linkify(text) {
  if (typeof text === 'string') {
    const regex = new RegExp(urlRegex({ strict: false }), 'mg');

    return text.replace(regex, getWrappedUrl);
  }

  return null;
}
