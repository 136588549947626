import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Radio, FormGroup, ControlLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import Label from '@/storychief/components/Label';
import { useDebounce, useDidUpdateEffect, usePrevious } from '@/storychief/hooks';
import getCustomFieldOptionValue from '@/storychief/utils/getCustomFieldOptionValue';
import CustomFieldValueLabels from '@/storychief/components/custom-fields/CustomFieldValueLabels';

const propTypes = {
  display: PropTypes.oneOf(['field', 'fieldPreview', 'fieldValue', 'datatable']),
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  fieldDefinition: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    important: PropTypes.bool,
    description: PropTypes.string,
    default: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  fieldValue: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  onUpdateCallback: PropTypes.func.isRequired,
  isDebounced: PropTypes.bool,
};
const defaultProps = {
  disabled: false,
  visible: true,
  display: 'field',
  isDebounced: false,
};

function RadioFieldType(props) {
  const { fieldDefinition, disabled, visible, display, fieldValue, isDebounced } = props;

  const [values, setValues] = useState(fieldValue.value || []);

  const prevVisible = usePrevious(visible);
  const prevDisabled = usePrevious(disabled);
  const prevDisplay = usePrevious(display);

  // Effects
  const change = useDebounce(values, isDebounced ? 400 : 0, null);

  useDidUpdateEffect(() => {
    handleSaveCustomFieldValues(change);
  }, [change]);

  useEffect(() => {
    // Overwrite the internal state with new prop values if the component is in view-only mode or if
    // the disabled value changes.
    if (disabled || disabled !== prevDisabled || prevDisplay !== 'field') {
      setValues(fieldValue.value || []);
    }
  }, [disabled, display, fieldValue.value]);

  useDidUpdateEffect(() => {
    if (!visible && prevVisible) {
      // Reset the field if it became invisible
      setValues([]);
    }
  }, [visible]);

  // Functions
  function handleOnChange(newValue) {
    if (newValue !== values[0]?.value) {
      const option = fieldDefinition.options.find((o) => o.value === newValue);
      setValues(option ? [getCustomFieldOptionValue(option)] : []);
    }
  }

  function handleSaveCustomFieldValues(changedValues) {
    props.onUpdateCallback({
      key: fieldValue.key,
      value: changedValues,
    });
  }

  if (!visible) {
    return null;
  }

  const checked = values.map((o) => o.value);
  const hasColoredOptions = fieldDefinition.options.some((o) => !!o.color);

  switch (display) {
    case 'datatable': {
      return <CustomFieldValueLabels values={values} />;
    }
    case 'fieldValue': {
      return (
        <div className="space-2">
          <CustomFieldValueLabels values={values} />
        </div>
      );
    }
    default:
      return (
        <FormGroup controlId={fieldDefinition.name} className="custom-field">
          <ControlLabel bsClass="control-label">
            {fieldDefinition.label}
            {fieldDefinition.important && !fieldDefinition.description && (
              <em className="icon-attention-alt text-warning" />
            )}
            {fieldDefinition.important && fieldDefinition.description && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`help-${fieldDefinition.name}`}>
                    Important! {fieldDefinition.description}
                  </Tooltip>
                }
              >
                <span className="icon-attention-alt text-warning" />
              </OverlayTrigger>
            )}
            {!fieldDefinition.important && fieldDefinition.description && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`help-${fieldDefinition.name}`}>
                    {fieldDefinition.description}
                  </Tooltip>
                }
              >
                <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
              </OverlayTrigger>
            )}
          </ControlLabel>
          <div>
            {fieldDefinition.options.map((option) => (
              <Radio
                key={option.value}
                name={fieldDefinition.name}
                value={option.value}
                onChange={() => handleOnChange(option.value)}
                checked={checked.includes(option.value)}
                disabled={disabled}
                className={hasColoredOptions ? 'radio--colored' : undefined}
              >
                {hasColoredOptions ? (
                  <Label
                    key={option.value}
                    variant={`color-coding--${option.color || 'none'}`}
                    onClick={() => handleOnChange(option.value)}
                  >
                    {option.label}
                  </Label>
                ) : (
                  option.label
                )}
              </Radio>
            ))}
          </div>
        </FormGroup>
      );
  }
}

RadioFieldType.propTypes = propTypes;
RadioFieldType.defaultProps = defaultProps;

export default RadioFieldType;
